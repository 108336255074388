import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { OnboardingService } from 'src/app/account/auth/services/onboarding.service';
import { ModalService } from '../services/modal.service';
import { DashboardService } from 'src/app/pages/services/dashboard.service';
import { Toaster } from 'ngx-toast-notifications';

@Component({
  selector: 'app-idle-modal',
  templateUrl: './idle-modal.component.html',
  styleUrls: ['./idle-modal.component.scss']
})
export class IdleModalComponent implements OnInit {
  idleForm: FormGroup;
  ismyTextFieldType: boolean;

  constructor(
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private authService: OnboardingService,
    private modalService: ModalService,
    private dashboardService: DashboardService,
    private toaster: Toaster,
  ) { }

  ngOnInit(): void {
    this.idleForm = this.formBuilder.group({
      password: [ '', [ Validators.required, Validators.pattern(/[^A-Za-z0-9]+/), Validators.minLength(8) ]],
    });
  }

  togglePasswordFieldType() {
    this.ismyTextFieldType = !this.ismyTextFieldType;
  }

  async unlockScreen(): Promise<void>{
    this.spinner.show();
    await this.modalService.unlockScreen(this.idleForm.value).toPromise().then((result: any) => {
      if (result.loginSuccessful) {
        this.spinner.hide();
        this.modalService.closeModal();
        sessionStorage.removeItem('idle');
        window.location.reload();
      } else {
        this.spinner.hide();
        this.toaster.open({ text: result.responseMessage || result.message, caption: 'User Details', type: 'dark' });
      }
    }, err => {
      this.spinner.hide();
      this.toaster.open({ text: err.error.responseMessage || err.error.message, caption: 'User Details', type: 'dark' });
    });
  }

  logout(): void{
    this.authService.logout();
    this.modalService.closeModal();
  }

}
