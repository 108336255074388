<ngx-spinner bdColor="rgba(0,0,0,0.5)" size="big" color="#D81616" type="ball-square-spin" [fullScreen]="true"></ngx-spinner>

<div class="modal-body" style="padding: 0px;">
    <div class="container-fluid p-0">
        <div class="" style="padding: 0px 70px;">

            <div class="row" *ngIf="confirmingTransactionView">
                <div class="col-xl-12">
                    <div class="text-center mt-5 pt-4" style="margin-bottom: 180px;">
                        <img src="assets/images/mine/checkoutLoading.png" height="60px" class="spinImage" />
                        <h5 class="text-black" style="margin-top: 80px;">Confirming your transaction</h5>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="successView">
                <div class="col-xl-12">
                    <div class="text-center mt-5">
                        <img src="assets/images/mine/whiteLogo.png" height="50" />
                    </div>
                    <div class="text-center mt-5">
                        <img src="assets/images/mine/successCheck.png" height="120" />
                    </div>
                    <div class="text-center">
                        <h5 class="text-black mt-2 pt-1">Payment Successful</h5>
                    </div>
                </div>
                <div class="col xl-12 mb-5 pb-4">
                    <div class="mt-4 d-grid">
                        <button class="btn btn-primary btn-block" (click)="doneTransfer()">Close the browser tab</button>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="errorView">
                <div class="col-xl-12">
                    <div class="text-center mt-5">
                        <img src="assets/images/mine/whiteLogo.png" height="50" />
                    </div>
                    <div class="text-center mt-5">
                        <img src="assets/images/mine/checkoutLoading.png" height="120" class="spinImage" />
                    </div>
                    <div class="text-center">
                        <h5 class="text-black mt-5 pt-1">Payment In Progress</h5>
                        <p>Transaction Not Confirmed Yet !!</p>
                    </div>
                </div>
                <div class="col xl-12 mb-5 pb-4">
                    <div class="mt-4 d-grid">
                        <button class="btn btn-primary btn-block" (click)="tryAgain()">Confirm Transaction</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>