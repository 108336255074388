<ngx-spinner bdColor="rgba(0,0,0,0.5)" size="big" color="#D81616" type="ball-square-spin" [fullScreen]="true"></ngx-spinner>

<div class="hidescroll">
    <div class="modal-body" style="padding: 0px;">
        <div class="container-fluid p-0">
            <div class="row g-0">

                <div class="col-12 col-xl-5 d-none d-sm-none d-md-block d-lg-block d-xl-block">
                    <div class="auth-full-bg pt-lg-5 p-4">
                        <div class="w-100">
                            <div class="bg-overlay"></div>
                            <div class="row">
                                <div class="col-lg-12" style="margin-top: 30px;">
                                    <div class="text-center mt-4">
                                        <h2 class="text-white fw-bold mb-1">Welcome to Spen</h2>
                                        <p class="text-white">We are happy to have you here</p>
                                    </div>
                                    <!-- <div class="row" style="margin-top: 90px;">
                                        <div class="col-xl-3">
                                            <div class="" style="background-color: #Fff; width: 40px; height: 40px; border-radius: 4px; margin-left: 95px;">
                                                <img src="assets/images/mine/kyc_chart.png" height="20px" style="padding: 0px 10px; margin-top: 10px;" />
                                            </div>
                                        </div>
                                        <div class="col-xl-8">
                                            <div class="" style="margin-left: 35px;">
                                                <h5 class="text-white mt-2 pt-1">Tell Us About Your Business</h5>
                                            </div>
                                        </div>
                                        <div class="col-xl-1">
                                            <div *ngIf="showNoBusiness" class="" style="background-color: #Fff; width: 55px; height: 55px; border-radius: 50%; border: 3px solid #2BC155; margin-left: 7px; margin-top: -5px;">
                                                <p class="text-danger fw-bold" style="padding: 9px 21px; font-size: 23px;">1</p>
                                            </div>
                                            <div *ngIf="showTickBusiness" class="" style="background-color: #Fff; width: 55px; height: 55px; border-radius: 50%; border: 3px solid #2BC155; margin-left: 7px; margin-top: -5px;">
                                                <img src="assets/images/mine/tick-circle.png" style="    padding: 8px 8px; height: 43px; margin-left: 4px; margin-top: 3px;" />
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="row" style="margin-top: 60px;">
                                        <div class="col-xl-3">
                                            <div *ngIf="showNoDirector" class="" style="background-color: #Fff; width: 40px; height: 40px; border-radius: 4px; margin-left: 95px; opacity: 0.4;">
                                                <img src="assets/images/mine/kyc_card.png" height="20px" style="padding: 0px 10px; margin-top: 10px;" />
                                            </div>
                                            <div *ngIf="showTextDirector" class="" style="background-color: #Fff; width: 40px; height: 40px; border-radius: 4px; margin-left: 95px;">
                                                <img src="assets/images/mine/kyc_card.png" height="20px" style="padding: 0px 10px; margin-top: 10px;" />
                                            </div>
                                        </div>
                                        <div class="col-xl-8">
                                            <div *ngIf="showNoDirector" class="" style="margin-left: 35px; opacity: 0.4;">
                                                <h5 class="text-white mt-2 pt-1">Let Us Know Your Directors</h5>
                                            </div>
                                            <div *ngIf="showTextDirector" class="" style="margin-left: 35px;">
                                                <h5 class="text-white mt-2 pt-1">Let Us Know Your Directors</h5>
                                            </div>
                                        </div>
                                        <div class="col-xl-1">
                                            <div *ngIf="showNoDirectorRed" class="" style="background-color: #Fff; width: 55px; height: 55px; border-radius: 50%; border: 3px solid #D81616; margin-left: 7px; margin-top: -5px; opacity: 0.4;">
                                                <p class="text-danger fw-bold" style="padding: 9px 21px; font-size: 23px;">1</p>
                                            </div>
                                            <div *ngIf="showNoDirectorGreen" class="" style="background-color: #Fff; width: 55px; height: 55px; border-radius: 50%; border: 3px solid #2BC155; margin-left: 7px; margin-top: -5px;">
                                                <p class="text-danger fw-bold" style="padding: 9px 21px; font-size: 23px;">1</p>
                                            </div>
                                            <div *ngIf="showTickDirector" class="" style="background-color: #Fff; width: 55px; height: 55px; border-radius: 50%; border: 3px solid #2BC155; margin-left: 7px; margin-top: -5px;">
                                                <img src="assets/images/mine/tick-circle.png" style="    padding: 8px 8px; height: 43px; margin-left: 4px; margin-top: 3px;" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" style="margin-top: 60px;">
                                        <div class="col-xl-3">
                                            <div *ngIf="showNoVerify" class="" style="background-color: #Fff; width: 40px; height: 40px; border-radius: 4px; margin-left: 95px; opacity: 0.4;">
                                                <img src="assets/images/mine/kyc_verify.png" height="20px" style="padding: 0px 10px; margin-top: 10px;" />
                                            </div>
                                            <div *ngIf="showTextVerify" class="" style="background-color: #Fff; width: 40px; height: 40px; border-radius: 4px; margin-left: 95px;">
                                                <img src="assets/images/mine/kyc_verify.png" height="20px" style="padding: 0px 10px; margin-top: 10px;" />
                                            </div>
                                        </div>
                                        <div class="col-xl-8">
                                            <div *ngIf="showNoVerify" class="" style="margin-left: 35px; opacity: 0.4;">
                                                <h5 class="text-white mt-2 pt-1">Let Us Verify You</h5>
                                            </div>
                                            <div *ngIf="showTextVerify" class="" style="margin-left: 35px;">
                                                <h5 class="text-white mt-2 pt-1">Let Us Verify You</h5>
                                            </div>
                                        </div>
                                        <div class="col-xl-1">
                                            <div *ngIf="showNoVerifyRed" class="" style="background-color: #Fff; width: 55px; height: 55px; border-radius: 50%; border: 3px solid #D81616; margin-left: 7px; margin-top: -5px; opacity: 0.4;">
                                                <p class="text-danger fw-bold" style="padding: 9px 21px; font-size: 23px;">2</p>
                                            </div>
                                            <div *ngIf="showNoVerifyGreen" class="" style="background-color: #Fff; width: 55px; height: 55px; border-radius: 50%; border: 3px solid #2BC155; margin-left: 7px; margin-top: -5px;">
                                                <p class="text-danger fw-bold" style="padding: 9px 21px; font-size: 23px;">2</p>
                                            </div>
                                            <div *ngIf="showTickVerify" class="" style="background-color: #Fff; width: 55px; height: 55px; border-radius: 50%; border: 3px solid #2BC155; margin-left: 7px; margin-top: -5px;">
                                                <img src="assets/images/mine/tick-circle.png" style="    padding: 8px 8px; height: 43px; margin-left: 4px; margin-top: 3px;" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end col -->

                <div class="col-12 col-xl-7">
                    <div class="auth-full-page-content p-md-5 p-4 pageAdjust">
                        <div class="w-100">

                            <div class="d-flex flex-column h-100">
                                <!-- <div class="my-auto" *ngIf="showBusinessInfo">
                                    <div class="row">
                                        <div class="col-xl-9">
                                            <h1 class="text-black fw-bold mb-0">Business Information</h1>
                                            <p class="text-muted" style="font-size: 17px;">Please enter your details.</p>
                                        </div>
                                        <div class="col-xl-3">
                                            <p class="text-primary fw-bold gotRight mt-4 cursor-pointer" (click)="doItLater()">Do it Later >></p>
                                        </div>
                                    </div>

                                    <div class="mt-3">
                                        <form autocomplete="off" [formGroup]="kycForm">

                                            <div class="mb-3">
                                                <label class="ml-2 text-muted">Business Name</label>
                                                <input type="email" class="form-control formEdit w-100" [(ngModel)]="businessNamePass" formControlName="businessName" placeholder="Enter Business Name" readonly>
                                            </div>
                                            <div class="mb-3">
                                                <label class="ml-2 text-muted">Business Category</label>
                                                <input type="email" class="form-control formEdit w-100" [(ngModel)]="businessCategoryPass" formControlName="businessCategory" placeholder="Enter Business Category" readonly>
                                            </div>
                                            <div class="mb-3">
                                                <label class="ml-2 text-muted">Business Type</label>
                                                <input type="email" class="form-control formEdit w-100" [(ngModel)]="businessTypePass" formControlName="businessType" placeholder="Enter Business Type" readonly>
                                            </div>
                                            <div class="mb-3">
                                                <label class="ml-2 text-muted">Business Type (If Others)</label>
                                                <input type="email" class="form-control formEdit w-100" formControlName="businessTypeOther" placeholder="Enter Business Type (If Others)" readonly>
                                            </div>
                                            <div class="mb-3">
                                                <label class="ml-2 text-muted">RC Number</label>
                                                <input [(ngModel)]="rcNumberToPass" [style.border-color]="kycForm.get('rcNumber')?.errors && (kycForm.get('rcNumber')?.touched || kycForm.get('rcNumber')?.dirty) ? '#D81616' : ''" type="text" class="form-control formEdit w-100" formControlName="rcNumber"
                                                    placeholder="Enter RC Number" readonly>
                                                <div class="mt-1">
                                                    <span class="text-danger" *ngIf="kycForm.get('rcNumber')?.errors && (kycForm.get('rcNumber')?.touched || kycForm.get('rcNumber')?.dirty)">
                                                  <span *ngIf="kycForm.get('rcNumber')?.errors?.['required']">RC Number is required</span>
                                                    <span *ngIf="kycForm.get('rcNumber')?.errors?.['pattern']">Invalid Input !!</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-8 col-xl-8">
                                                <div class="mb-3">
                                                    <label class="ml-2 text-muted">Social Media Handles</label>
                                                    <input [(ngModel)]="socialLinkToPass" [style.border-color]=" kycForm.get('socialLink')?.errors && (kycForm.get('socialLink')?.touched || kycForm.get('socialLink')?.dirty) ? '#D81616' : ''" type="text" class="form-control formEdit w-100" formControlName="socialLink"
                                                        placeholder="Enter Social Media Handle" readonly>
                                                    <div class="mt-1">
                                                        <span class="text-danger" *ngIf="kycForm.get('socialLink')?.errors && (kycForm.get('socialLink')?.touched || kycForm.get('socialLink')?.dirty)">
                                                      <span *ngIf="kycForm.get('socialLink')?.errors?.['required']">Social Media is required</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="mt-5">
                                                <div class="row">
                                                    <div class="col-4 col-xl-4"></div>
                                                    <div class="col-4 col-xl-4"></div>
                                                    <div class="col-4 col-xl-4">
                                                        <div class="d-grid">
                                                            <button class="btn btn-primary btn-block" (click)="proceed('first')">Proceed</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div> -->

                                <div class="my-auto" *ngIf="showDirectorInfo">
                                    <div class="row">
                                        <div class="col-xl-9">
                                            <h1 class="text-black fw-bold mb-0">Director's Information</h1>
                                            <p class="text-muted" style="font-size: 17px;">Please enter your details.</p>
                                        </div>
                                        <div class="col-xl-3">
                                            <p class="text-primary fw-bold gotRight mt-4 cursor-pointer" (click)="doItLater()">Do it Later >></p>
                                        </div>
                                    </div>

                                    <div class="mt-3">
                                        <form autocomplete="off" [formGroup]="kycForm">
                                            <div class="row">
                                                <div class="col-xl-6">
                                                    <div class="mb-3">
                                                        <label class="ml-2 text-muted">First Name</label>
                                                        <input [style.border-color]=" kycForm.get('firstName')?.errors && (kycForm.get('firstName')?.touched || kycForm.get('firstName')?.dirty) ? '#D81616' : ''" type="text" class="form-control formEdit w-100" formControlName="firstName" placeholder="Enter First Name">
                                                        <div class="mt-1">
                                                            <span class="text-danger" *ngIf="kycForm.get('firstName')?.errors && (kycForm.get('firstName')?.touched || kycForm.get('firstName')?.dirty)">
                                                    <span *ngIf="kycForm.get('firstName')?.errors?.['required']">First Name is required</span>
                                                            <span *ngIf="kycForm.get('firstName')?.errors?.['pattern']">Invalid input !!</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-6">
                                                    <div class="mb-3">
                                                        <label class="ml-2 text-muted">Last Name</label>
                                                        <input [style.border-color]=" kycForm.get('lastName')?.errors && (kycForm.get('lastName')?.touched || kycForm.get('lastName')?.dirty) ? '#D81616' : ''" type="text" class="form-control formEdit w-100" formControlName="lastName" placeholder="Enter Last Name">
                                                        <div class="mt-1">
                                                            <span class="text-danger" *ngIf="kycForm.get('lastName')?.errors && (kycForm.get('lastName')?.touched || kycForm.get('lastName')?.dirty)">
                                                    <span *ngIf="kycForm.get('lastName')?.errors?.['required']">Last Name is required</span>
                                                            <span *ngIf="kycForm.get('lastName')?.errors?.['pattern']">Invalid input !!</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row g-2">
                                                <label class="ml-2 text-muted" style="margin-bottom: -6px;">Phone Number</label>
                                                <div class="col-4 col-xl-2">
                                                    <div class="mb-3">
                                                        <select class="form-select formEdit w-100" formControlName="phoneShort">
                                                        <option selected value="">+234</option>
                                                        <option value="+233">+233</option>
                                                        <option value="+235">+235</option>
                                                      </select>
                                                    </div>
                                                </div>
                                                <div class="col-8 col-xl-10">
                                                    <div class="mb-3">
                                                        <input [style.border-color]=" kycForm.get('phoneNumber')?.errors && (kycForm.get('phoneNumber')?.touched || kycForm.get('phoneNumber')?.dirty) ? '#D81616' : ''" type="text" class="form-control formEdit w-100" formControlName="phoneNumber" placeholder="Enter Phone Number">
                                                        <div class="mt-1">
                                                            <span class="text-danger" *ngIf="kycForm.get('phoneNumber')?.errors && (kycForm.get('phoneNumber')?.touched || kycForm.get('phoneNumber')?.dirty)">
                                                        <span *ngIf="kycForm.get('phoneNumber')?.errors?.['required']">Phone Number is required</span>
                                                            <span *ngIf="kycForm.get('phoneNumber')?.errors?.['pattern']">Invalid input !!</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="mb-3">
                                                <label class="ml-2 text-muted">Nationality</label>
                                                <select class="form-select formEdit w-100" formControlName="nationality">
                                                  <option selected value="">Select Nationality</option>
                                                  <option *ngFor="let list of countryList" value="{{ list.name }}">{{ list.name }}</option>
                                                </select>
                                            </div>

                                            <div class="mb-3">
                                                <label class="ml-2 text-muted">Bank Verification Number</label>
                                                <input [style.border-color]=" kycForm.get('bvn')?.errors && (kycForm.get('bvn')?.touched || kycForm.get('bvn')?.dirty) ? '#D81616' : ''" type="text" class="form-control formEdit w-100" formControlName="bvn" placeholder="Enter BVN Here">
                                                <div class="mt-1">
                                                    <span class="text-danger" *ngIf="kycForm.get('bvn')?.errors && (kycForm.get('bvn')?.touched || kycForm.get('bvn')?.dirty)">
                                                  <span *ngIf="kycForm.get('bvn')?.errors?.['required']">BVN is required</span>
                                                    <span *ngIf="kycForm.get('bvn')?.errors?.['pattern']">Invalid input !!</span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="mb-3">
                                                <label class="ml-2 text-muted">Date of Birth</label>
                                                <input [style.border-color]=" kycForm.get('dateOfBirth')?.errors && (kycForm.get('dateOfBirth')?.touched || kycForm.get('dateOfBirth')?.dirty) ? '#D81616' : ''" type="date" class="form-control formEdit w-100" formControlName="dateOfBirth" placeholder="Enter DOB">
                                                <div class="mt-1">
                                                    <span class="text-danger" *ngIf="kycForm.get('dateOfBirth')?.errors && (kycForm.get('dateOfBirth')?.touched || kycForm.get('dateOfBirth')?.dirty)">
                                                  <span *ngIf="kycForm.get('dateOfBirth')?.errors?.['required']">Date Of Birth is required</span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="mb-3">
                                                <label class="ml-2 text-muted">Email</label>
                                                <input [style.border-color]=" kycForm.get('email')?.errors && (kycForm.get('email')?.touched || kycForm.get('email')?.dirty) ? '#D81616' : ''" type="email" class="form-control formEdit w-100" formControlName="email" placeholder="Enter email">
                                                <div class="mt-1">
                                                    <span class="text-danger" *ngIf="kycForm.get('email')?.errors && (kycForm.get('email')?.touched || kycForm.get('email')?.dirty)">
                                                    <span *ngIf="kycForm.get('email')?.errors?.['required']">Email is required</span>
                                                    <span *ngIf="kycForm.get('email')?.errors?.['pattern']">Invalid email address</span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="mb-3">
                                                <label class="ml-2 text-muted">Residential Address</label>
                                                <input [style.border-color]=" kycForm.get('residentialAddress')?.errors && (kycForm.get('residentialAddress')?.touched || kycForm.get('residentialAddress')?.dirty) ? '#D81616' : ''" type="text" class="form-control formEdit w-100" formControlName="residentialAddress"
                                                    placeholder="Enter Residential Address">
                                                <div class="mt-1">
                                                    <span class="text-danger" *ngIf="kycForm.get('residentialAddress')?.errors && (kycForm.get('residentialAddress')?.touched || kycForm.get('residentialAddress')?.dirty)">
                                                  <span *ngIf="kycForm.get('residentialAddress')?.errors?.['required']">Residential Address is required</span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="mt-5">
                                                <div class="row">
                                                    <div class="col-4 col-xl-4">
                                                        <div class="d-grid">
                                                            <!-- <button class="btn btn-outline-dark btn-block" (click)="goBack('first')">Go Back</button> -->
                                                        </div>
                                                    </div>
                                                    <div class="col-4 col-xl-4"></div>
                                                    <div class="col-4 col-xl-4">
                                                        <div class="d-grid">
                                                            <button class="btn btn-primary btn-block" (click)="proceed('second')">Proceed</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                        </form>
                                    </div>
                                </div>

                                <div class="my-auto" *ngIf="showUploadInfo">
                                    <div class="row">
                                        <div class="col-xl-9">
                                            <h1 class="text-black fw-bold mb-0">Upload Documents</h1>
                                            <p class="text-muted" style="font-size: 17px;">Please upload your documents. (PDF. JPG. PNG)</p>
                                        </div>
                                        <div class="col-xl-3">
                                            <p class="text-primary fw-bold gotRight mt-4 cursor-pointer" (click)="doItLater()">Do it Later >></p>
                                        </div>
                                    </div>

                                    <div class="mt-3">

                                        <div class="p-2" *ngIf="showCifResult" [ngClass]="cifUploadClass ? 'uploadSuccess' : 'uploadDanger'">
                                            <div class="row">
                                                <div class="col-8 col-xl-8">
                                                    <p style="font-size: 12px; font-weight: 400; color: #909090;">Certificate of Incorporation</p>
                                                </div>
                                                <div class="col-4 col-xl-4">
                                                    <div class="cursor-pointer" style="float: right" (click)="deleteUploadedFile('cif')">
                                                        <img [src]="cifUploadClass ? 'assets/images/mine/trash.png' : 'assets/images/mine/x.png'" height="16" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-2 col-xl-1">
                                                    <div class="">
                                                        <img [src]="cifUploadClass ? 'assets/images/mine/document-text-success.png' : 'assets/images/mine/document-text-danger.png'" [ngClass]="cifUploadClass ? 'successIconFile' : 'dangerIconFile'" />
                                                    </div>
                                                </div>
                                                <div class="col-10 col-xl-11">
                                                    <div class="d-flex justify-content-between">
                                                        <p class="mb-0">{{ cifFileName }}</p>
                                                        <p class="mb-0">{{ cifUploadClass ? 'complete' : '' }}</p>
                                                    </div>
                                                    <div class="">
                                                        <hr class="mt-2 mb-0 barSuccess" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="uploadNormal upload-wrapper p-2 " *ngIf="showCifUpload">
                                            <div class="row">
                                                <div class="col-12 col-xl-12">
                                                    <p style="font-size: 12px; font-weight: 400; color: #909090;">Certificate of Incorporation</p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-2 col-xl-1">
                                                    <div class="">
                                                        <img src="assets/images/mine/document-text-normal.png" class="normalIconFile" />
                                                    </div>
                                                </div>
                                                <div class="col-10 col-xl-11">
                                                    <div class="d-flex justify-content-between">
                                                        <p class="mb-0 fw-medium mt-1" style="font-size: 15px;">Upload Your Certificate of Incorporation</p>
                                                        <span class="mb-0" style="margin-top: -13px;">
                                                          <img src="assets/images/mine/document-upload.png" height="16" />
                                                        </span>

                                                    </div>
                                                </div>
                                            </div>
                                            <input type="file" class="w-100 cursor-poiner" accept=".jpg, .png, .bmp, .pdf" (change)="uploadCif($event)" />
                                        </div>

                                        <div class="p-2 mt-3" *ngIf="showMemResult" [ngClass]="memUploadClass ? 'uploadSuccess' : 'uploadDanger'">
                                            <div class="row">
                                                <div class="col-8 col-xl-8">
                                                    <p style="font-size: 12px; font-weight: 400; color: #909090;">Memorandum of Association</p>
                                                </div>
                                                <div class="col-4 col-xl-4">
                                                    <div class="cursor-pointer" style="float: right" (click)="deleteUploadedFile('mem')">
                                                        <img [src]="memUploadClass ? 'assets/images/mine/trash.png' : 'assets/images/mine/x.png'" height="16" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-2 col-xl-1">
                                                    <div class="">
                                                        <img [src]="memUploadClass ? 'assets/images/mine/document-text-success.png' : 'assets/images/mine/document-text-danger.png'" [ngClass]="memUploadClass ? 'successIconFile' : 'dangerIconFile'" />
                                                    </div>
                                                </div>
                                                <div class="col-10 col-xl-11">
                                                    <div class="d-flex justify-content-between">
                                                        <p class="mb-0">{{ memFileName }}</p>
                                                        <p class="mb-0">{{ memUploadClass ? 'complete' : '' }}</p>
                                                    </div>
                                                    <div class="">
                                                        <hr class="mt-2 mb-0 barSuccess" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="uploadNormal upload-wrapper p-2 mt-3" *ngIf="showMemUpload">
                                            <div class="row">
                                                <div class="col-12 col-xl-12">
                                                    <p style="font-size: 12px; font-weight: 400; color: #909090;">Memorandum of Association</p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-2 col-xl-1">
                                                    <div class="">
                                                        <img src="assets/images/mine/document-text-normal.png" class="normalIconFile" />
                                                    </div>
                                                </div>
                                                <div class="col-10 col-xl-11">
                                                    <div class="d-flex justify-content-between">
                                                        <p class="mb-0 fw-medium mt-1" style="font-size: 15px;">Upload Your Memorandum of Association</p>
                                                        <span class="mb-0" style="margin-top: -13px;">
                                                        <img src="assets/images/mine/document-upload.png" height="16" />
                                                      </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <input type="file" class="w-100 cursor-poiner" accept=".jpg, .png, .bmp, .pdf" (change)="uploadMem($event)" />
                                        </div>

                                        <div class="p-2 mt-3" *ngIf="showNinResult" [ngClass]="ninUploadClass ? 'uploadSuccess' : 'uploadDanger'">
                                            <div class="row">
                                                <div class="col-8 col-xl-8">
                                                    <p style="font-size: 12px; font-weight: 400; color: #909090;">National Identification Number</p>
                                                </div>
                                                <div class="col-4 col-xl-4">
                                                    <div class="cursor-pointer" style="float: right" (click)="deleteUploadedFile('nin')">
                                                        <img [src]="ninUploadClass ? 'assets/images/mine/trash.png' : 'assets/images/mine/x.png'" height="16" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-2 col-xl-1">
                                                    <div class="">
                                                        <img [src]="ninUploadClass ? 'assets/images/mine/document-text-success.png' : 'assets/images/mine/document-text-danger.png'" [ngClass]="ninUploadClass ? 'successIconFile' : 'dangerIconFile'" />
                                                    </div>
                                                </div>
                                                <div class="col-10 col-xl-11">
                                                    <div class="d-flex justify-content-between">
                                                        <p class="mb-0">{{ ninFileName }}</p>
                                                        <p class="mb-0">{{ ninUploadClass ? 'complete' : '' }}</p>
                                                    </div>
                                                    <div class="">
                                                        <hr class="mt-2 mb-0 barSuccess" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="uploadNormal upload-wrapper p-2 mt-3" *ngIf="showNinUpload">
                                            <div class="row">
                                                <div class="col-12 col-xl-12">
                                                    <p style="font-size: 12px; font-weight: 400; color: #909090;">National Identification Number</p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-2 col-xl-1">
                                                    <div class="">
                                                        <img src="assets/images/mine/document-text-normal.png" class="normalIconFile" />
                                                    </div>
                                                </div>
                                                <div class="col-10 col-xl-11">
                                                    <div class="d-flex justify-content-between">
                                                        <p class="mb-0 fw-medium mt-1" style="font-size: 15px;">Upload Your NIN</p>
                                                        <span class="mb-0" style="margin-top: -13px;">
                                                        <img src="assets/images/mine/document-upload.png" height="16" />
                                                      </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <input type="file" class="w-100 cursor-poiner" accept=".jpg, .png, .bmp, .pdf" (change)="uploadNin($event)" />
                                        </div>

                                        <div class="mt-5">
                                            <div class="row">
                                                <div class="col-4 col-xl-4">
                                                    <div class="d-grid">
                                                        <button class="btn btn-outline-dark btn-block" (click)="goBack('second')">Go Back</button>
                                                    </div>
                                                </div>
                                                <div class="col-4 col-xl-4"></div>
                                                <div class="col-4 col-xl-4">
                                                    <div class="d-grid">
                                                        <button class="btn btn-primary btn-block" *ngIf="userRole.includes('merchant-admin') || userRole.includes('merchant-support') || userRole.includes('merchant-treasury')" (click)="proceed('third')">Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="my-auto" *ngIf="showFinal">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="text-center">
                                                <img src="assets/images/mine/tick-square.png" class="finalImg" />
                                                <h3 class="fw-bold mb-0 mt-4" style="color: #292E35;">Upload Successful</h3>
                                                <p class="mt-1 mb-2" style="color: #909090;">You have successfully completed uploading your<br> documents.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mt-4">
                                        <div class="mt-5">
                                            <div class="row">
                                                <div class="col-xl-4"></div>
                                                <div class="col-xl-4">
                                                    <div class="d-grid">
                                                        <button class="btn btn-primary btn-block" (click)="toDashboard()">Go To Dashboard</button>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end col -->

            </div>
            <!-- end row -->
        </div>
    </div>
</div>