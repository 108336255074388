import { Component, OnInit, Renderer2  } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { OnboardingService } from 'src/app/account/auth/services/onboarding.service';
import { ModalService } from '../services/modal.service';
import { ClipboardService } from 'ngx-clipboard';
import { CardtypeService } from '../services/cardtype.service';

@Component({
  selector: 'app-checkoutmodal',
  templateUrl: './checkoutmodal.component.html',
  styleUrls: ['./checkoutmodal.component.scss']
})
export class CheckoutmodalComponent implements OnInit {

  cardForm: FormGroup;
  ussdForm: FormGroup;
  transferForm: FormGroup;
  otpForm: FormGroup;

  transferCheck: boolean;
  ussdCheck: boolean;
  cardCheck = true;

  otherDetails = true;
  cardFirstView = true;
  cardSecondView = false;
  ussdFirstView = false;
  ussdSecondView = false;
  transferFirstView = false;
  transferSecondView = false;
  confirmingTransactionView = false;
  successView = false;
  referenceDetails: any;
  transferAccountDetails: any;
  paymentRefToSend: any;
  displayTime: any;
  redirectUrl = 'https://sandbox-apigateway.myspen.com/spen-hooks/api/v1/webhook/visa-postback';

  bankList = [
    {
      BankName: 'Globus Bank',
      BankCode: '989'
    },
    {
      BankName: 'Rubies (Highstreet) MFB',
      BankCode: '7797'
    },
    {
      BankName: 'VFD MFB',
      BankCode: '5037'
    },
    {
      BankName: 'Union Bank',
      BankCode: '826'
    },
    {
      BankName: 'Heritage Bank',
      BankCode: '745'
    },
    {
      BankName: 'FCMB',
      BankCode: '329'
    },
    {
      BankName: 'Access (Diamond ) Bank',
      BankCode: '426'
    },
    {
      BankName: 'Access Bank',
      BankCode: '901'
    },
    {
      BankName: 'Wema Bank',
      BankCode: '945'
    },
    {
      BankName: 'Eco Bank',
      BankCode: '326'
    },
    {
      BankName: 'Keystone Bank',
      BankCode: '7111'
    },
    {
      BankName: 'Fidelity Bank',
      BankCode: '770'
    },
    {
      BankName: 'Unity Bank',
      BankCode: '7799'
    },
    {
      BankName: 'GTB',
      BankCode: '737'
    },
    {
      BankName: 'First Bank',
      BankCode: '894'
    },
    {
      BankName: 'Zenith Bank',
      BankCode: '966'
    },
    {
      BankName: 'UBA',
      BankCode: '919'
    },
    {
      BankName: 'Stanbic Bank',
      BankCode: '909'
    },
    {
      BankName: 'Sterling Bank',
      BankCode: '822'
    }
  ];
  ussdAccountDetails: any;
  ussdBankInfo: { BankName: string; BankCode: string; };
  cardType: string;
  allCardDetails: any;
  otpPinMessage: any;
  userAgent: string;
  isJavaEnabled: boolean;
  ismyTextFieldType: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toaster: Toaster,
    private onboardingService: OnboardingService,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private clipBoard: ClipboardService,
    private renderer: Renderer2,
    private router: Router,
    private cardTypeService: CardtypeService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.cardForm = this.formBuilder.group({
      cardNo: [ '', [ Validators.required, Validators.pattern('^[0-9]*$')]],
      expireDate: [ '', [ Validators.required, Validators.pattern('^[0-9/]*$')]],
      cvv: [ '', [ Validators.required, Validators.pattern('^[0-9]*$')]],
      cardPin: [ '', [ Validators.required, Validators.pattern('^[0-9]*$')]],
      customerName: [ '', Validators.required],
    });
    this.otpForm = this.formBuilder.group({
      otp1: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      otp2: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      otp3: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      otp4: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      otp5: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      otp6: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
    });

    this.ussdForm = this.formBuilder.group({
      bank: [ '', Validators.required],
      customerName: [ '', Validators.required],
    });
    this.transferForm = this.formBuilder.group({
      customerName: [ '', Validators.required],
    });

    await this.getUrlInformation();

  }

  togglePasswordFieldTyped() {
    this.ismyTextFieldType = !this.ismyTextFieldType;
  }

  async getUrlInformation(): Promise<void>{
    this.spinner.show();
    this.route.queryParams.subscribe(async params => {
      this.paymentRefToSend = params.paymentReference;

      await this.modalService.getReferenceDetails(this.paymentRefToSend).toPromise().then((result: any) => {
        if (result.isSuccessful) {
          this.spinner.hide();
          this.referenceDetails = result.data;
          // sessionStorage.setItem('reference', this.paymentRefToSend);
        } else {
          this.spinner.hide();
          this.toaster.open({ text: result.responseMessage || result.message, caption: 'Merchant Reference', type: 'dark' });
        }
      }, err => {
        this.spinner.hide();
        this.toaster.open({ text: err.error.responseMessage || err.error.message, caption: 'Merchant Reference', type: 'dark' });
      });

    });
  }

  async check(val: any): Promise<void>{
    if (val === 'card') {
      this.cardFirstView = true;
      this.cardSecondView = false;
      this.ussdFirstView = false;
      this.ussdSecondView = false;
      this.transferFirstView = false;
      this.transferSecondView = false;
    } else if (val === 'ussd') {
      this.cardFirstView = false;
      this.cardSecondView = false;
      this.ussdFirstView = true;
      this.ussdSecondView = false;
      this.transferFirstView = false;
      this.transferSecondView = false;
    } else {
      this.cardFirstView = false;
      this.cardSecondView = false;
      this.ussdFirstView = false;
      this.ussdSecondView = false;
      this.transferFirstView = true;
      this.transferSecondView = false;
    }
  }

  getCardType(): void{
    this.cardType = this.cardTypeService.detectCardType(this.cardForm.value.cardNo);
    console.log('card', this.cardType);
  }

  checkJavaScriptEnabled(): boolean {
    try {
      return true;
    } catch (error) {
      return false;
    }
  }

  checkJavaEnabled(): void {
    try {
      const applet = document.createElement('applet') as HTMLElement;
      applet.setAttribute('width', '1');
      applet.setAttribute('height', '1');
      document.body.appendChild(applet);
      this.isJavaEnabled = true;
      document.body.removeChild(applet);
    } catch (error) {
      this.isJavaEnabled = false;
    }
  }

  async proceed1(val: any): Promise<void>{
    if (val === 'card') {
      if (this.cardType === 'Unknown') {
        this.spinner.show();
        const payload = {
          customerId: this.referenceDetails.email,
          pan: this.cardForm.value.cardNo,
          pin: this.cardForm.value.cardPin,
          expiryDate: this.cardForm.value.expireDate,
          cvv: this.cardForm.value.cvv,
          amount: this.referenceDetails.amount.toString(),
          narration: this.referenceDetails.narration,
          redirectUrl: this.redirectUrl,
          nameOnCard: this.cardForm.value.customerName,
          cardScheme: 'Verve',
          paymentReference: this.referenceDetails.transactionReference,
          isCheckout: true
        };
        // tslint:disable-next-line:max-line-length
        await this.modalService.getPaymentAccountCardMasterAndVerve(this.referenceDetails.merchantId, payload).toPromise().then((result: any) => {
          if (result.isSuccessful) {
            this.spinner.hide();

            if (result.provider === 'myRoute2') {
              if (result.requires3DS === true){
                this.allCardDetails = result;
                this.modalService.setValue(this.allCardDetails);
                this.modalService.closeModal();
                // this.router.navigateByUrl('account/visa-card-payment-confirm');
              } else {
                if (result.responseCode === 'T00') {
                  this.cardFirstView = false;
                  this.cardSecondView = true;
                  this.allCardDetails = result;
                  this.otpPinMessage = result.responseMessage;
                } else if (result.responseCode === '000') {
                  this.toaster.open({ text: 'Please confirm transaction !!', caption: 'Card', type: 'success' });
                } else {
                  this.toaster.open({ text: result.responseMessage || result.message, caption: 'Card', type: 'dark' });
                }
              }
            } else if (result.provider === 'myRoute1') {
              if (result.requires3DS === true){
                this.allCardDetails = result;
                this.modalService.setValue(this.allCardDetails);
                this.modalService.closeModal();
                this.router.navigateByUrl('account/visa-card-payment-confirm2');
              } else {
                this.cardFirstView = false;
                this.cardSecondView = true;
                this.allCardDetails = result;
                this.otpPinMessage = result.responseMessage;
              }
            }

          } else {
            console.log('checking error in else', result.responseMessage);
            this.spinner.hide();
            this.toaster.open({ text: result.responseMessage || result.message, caption: 'Card', type: 'dark' });
          }
        }, err => {
          this.spinner.hide();
          this.toaster.open({ text: err.error.responseMessage || err.error.message, caption: 'Card', type: 'dark' });
        });
      } else if (this.cardType === 'MasterCard' || this.cardType === 'Verve') {
        this.spinner.show();
        const payload = {
          customerId: this.referenceDetails.email,
          pan: this.cardForm.value.cardNo,
          pin: this.cardForm.value.cardPin,
          expiryDate: this.cardForm.value.expireDate,
          cvv: this.cardForm.value.cvv,
          amount: this.referenceDetails.amount.toString(),
          narration: this.referenceDetails.narration,
          redirectUrl: this.redirectUrl,
          nameOnCard: this.cardForm.value.customerName,
          cardScheme: this.cardType,
          paymentReference: this.referenceDetails.transactionReference,
          isCheckout: true
        };
        // tslint:disable-next-line:max-line-length
        await this.modalService.getPaymentAccountCardMasterAndVerve(this.referenceDetails.merchantId, payload).toPromise().then((result: any) => {
          if (result.isSuccessful) {
            this.spinner.hide();

            if (result.provider === 'myRoute2') {
              if (result.requires3DS === true){
                this.allCardDetails = result;
                this.modalService.setValue(this.allCardDetails);
                this.modalService.closeModal();
                // this.router.navigateByUrl('account/visa-card-payment-confirm');
              } else {
                if (result.responseCode === 'T00') {
                  this.cardFirstView = false;
                  this.cardSecondView = true;
                  this.allCardDetails = result;
                  this.otpPinMessage = result.responseMessage;
                } else if (result.responseCode === '000') {
                  this.toaster.open({ text: 'Please confirm transaction !!', caption: 'Card', type: 'success' });
                } else {
                  this.toaster.open({ text: result.responseMessage || result.message, caption: 'Card', type: 'dark' });
                }
              }
            } else if (result.provider === 'myRoute1') {
              if (result.requires3DS === true){
                this.allCardDetails = result;
                this.modalService.setValue(this.allCardDetails);
                this.modalService.closeModal();
                this.router.navigateByUrl('account/visa-card-payment-confirm2');
              } else {
                this.cardFirstView = false;
                this.cardSecondView = true;
                this.allCardDetails = result;
                this.otpPinMessage = result.responseMessage;
              }
            }

          } else {
            this.spinner.hide();
            this.toaster.open({ text: result.responseMessage || result.message, caption: 'Card', type: 'dark' });
          }
        }, err => {
          this.spinner.hide();
          this.toaster.open({ text: err.error.responseMessage || err.error.message, caption: 'Card', type: 'dark' });
        });
      }  else if (this.cardType === 'Visa') {
        // Get the user agent value
        const userAgent = navigator.userAgent;
        // Get Javascript Enabled
        const isJavaScriptEnabled = this.checkJavaScriptEnabled();
        // Get Java Enabled
        this.checkJavaEnabled();
        this.spinner.show();
        const payload = {
          customerId: this.referenceDetails.email,
          pan: this.cardForm.value.cardNo,
          // pin: this.cardForm.value.cardPin,
          expiryDate: this.cardForm.value.expireDate,
          cvv: this.cardForm.value.cvv,
          amount: this.referenceDetails.amount.toString(),
          narration: this.referenceDetails.narration,
          redirectUrl: this.redirectUrl,
          nameOnCard: this.cardForm.value.customerName,
          cardScheme: this.cardType,
          paymentReference: this.referenceDetails.transactionReference,
          isCheckout: true,
          deviceInformation: {
            httpBrowserLanguage: 'en-US',
            httpBrowserJavaEnabled: this.isJavaEnabled,
            httpBrowserJavaScriptEnabled: isJavaScriptEnabled,
            httpBrowserColorDepth: 24,
            httpBrowserScreenHeight: 820,
            httpBrowserScreenWidth: 360,
            httpBrowserTimeDifference: '',
            userAgentBrowserValue: userAgent,
            deviceChannel: 'Browser'
          }
        };
        // tslint:disable-next-line:max-line-length
        await this.modalService.getPaymentAccountCardMasterAndVerve(this.referenceDetails.merchantId, payload).toPromise().then((result: any) => {
          if (result.isSuccessful) {
            this.spinner.hide();

            if (result.provider === 'myRoute2') {
              if (result.requires3DS === true){
                this.allCardDetails = result;
                this.modalService.setValue(this.allCardDetails);
                this.modalService.closeModal();
                this.router.navigateByUrl('account/visa-card-payment-confirm');
              } else {
                if (result.responseCode === 'T00') {
                  this.cardFirstView = false;
                  this.cardSecondView = true;
                  this.allCardDetails = result;
                  this.otpPinMessage = result.responseMessage;
                } else if (result.responseCode === '000') {
                  this.toaster.open({ text: 'Please confirm transaction !!', caption: 'Card', type: 'success' });
                } else {
                  this.toaster.open({ text: result.responseMessage || result.message, caption: 'Card', type: 'dark' });
                }
              }
            } else if (result.provider === 'myRoute1') {
              if (result.requires3DS === true){
                this.allCardDetails = result;
                this.modalService.setValue(this.allCardDetails);
                this.modalService.closeModal();
                this.router.navigateByUrl('account/visa-card-payment-confirm2');
              } else {
                this.cardFirstView = false;
                this.cardSecondView = true;
                this.allCardDetails = result;
                this.otpPinMessage = result.responseMessage;
              }
            }

          } else {
            this.spinner.hide();
            this.toaster.open({ text: result.responseMessage || result.message, caption: 'Card', type: 'dark' });
          }
        }, err => {
          this.spinner.hide();
          this.toaster.open({ text: err.error.responseMessage || err.error.message, caption: 'Card', type: 'dark' });
        });
      } else {
        this.toaster.open({ text: 'Not a valid card number', caption: 'Card', type: 'dark' });
      }
    } else if (val === 'ussd') {
      this.spinner.show();
      const bankAll = this.bankList.find(x => x.BankCode === this.ussdForm.value.bank);
      this.ussdBankInfo = bankAll;
      const payload = {
        paymentReference: this.paymentRefToSend,
        channel: 'virtual_account',
        customerName: this.ussdForm.value.customerName,
        bankCode: this.ussdForm.value.bank
      };
      await this.modalService.getPaymentAccountUssd(this.referenceDetails.merchantId, payload).toPromise().then((result: any) => {
        if (result.isSuccessful) {
          this.timer(30);
          this.spinner.hide();
          this.ussdAccountDetails = result.data;
          this.ussdFirstView = false;
          this.ussdSecondView = true;
        } else {
          this.spinner.hide();
          this.toaster.open({ text: result.responseMessage || result.message, caption: 'Transfer', type: 'dark' });
        }
      }, err => {
        this.spinner.hide();
        this.toaster.open({ text: err.error.responseMessage || err.error.message, caption: 'Transfer', type: 'dark' });
      });
    } else {
      this.spinner.show();
      const payload = {
        paymentReference: this.paymentRefToSend,
        channel: 'virtual_account',
        customerName: this.transferForm.value.customerName
      };
      await this.modalService.getPaymentAccountTransfer(this.referenceDetails.merchantId, payload).toPromise().then((result: any) => {
        if (result.isSuccessful) {
          this.timer(30);
          this.spinner.hide();
          this.transferAccountDetails = result.data;
          this.transferFirstView = false;
          this.transferSecondView = true;
        } else {
          this.spinner.hide();
          this.toaster.open({ text: result.responseMessage || result.message, caption: 'Transfer', type: 'dark' });
        }
      }, err => {
        this.spinner.hide();
        this.toaster.open({ text: err.error.responseMessage || err.error.message, caption: 'Transfer', type: 'dark' });
      });
    }
  }

  async proceed2(val: any): Promise<void>{
    if (val === 'card') {
      this.otherDetails = false;
      this.cardFirstView = false;
      this.cardSecondView = false;
      this.ussdFirstView = false;
      this.ussdSecondView = false;
      this.transferFirstView = false;
      this.transferSecondView = false;
      this.confirmingTransactionView = true;
      const payload = {
        transactionReference: this.allCardDetails.transactionReference,
        otp: `${this.otpForm.value.otp1}${this.otpForm.value.otp2}${this.otpForm.value.otp3}${this.otpForm.value.otp4}${this.otpForm.value.otp5}${this.otpForm.value.otp6}`
      };
      await this.modalService.validateCardPaymentOtp(payload).toPromise().then((result: any) => {
        if (result.isSuccessful) {
          this.otherDetails = false;
          this.cardFirstView = false;
          this.cardSecondView = false;
          this.confirmingTransactionView = false;
          this.successView = true;
        } else {
          this.otherDetails = true;
          this.cardFirstView = false;
          this.cardSecondView = true;
          this.ussdFirstView = false;
          this.ussdSecondView = false;
          this.transferFirstView = false;
          this.transferSecondView = false;
          this.confirmingTransactionView = false;
          this.transferCheck = false;
          this.ussdCheck = false;
          this.cardCheck = true;
          this.toaster.open({ text: result.responseMessage || result.message, caption: 'OTP', type: 'dark' });
        }
      }, err => {
        this.otherDetails = true;
        this.cardFirstView = false;
        this.cardSecondView = true;
        this.ussdFirstView = false;
        this.ussdSecondView = false;
        this.transferFirstView = false;
        this.transferSecondView = false;
        this.confirmingTransactionView = false;
        this.transferCheck = false;
        this.ussdCheck = false;
        this.cardCheck = true;
        this.toaster.open({ text: err.error.responseMessage || err.error.message, caption: 'OTP', type: 'dark' });
      });
    } else if (val === 'ussd') {
      this.otherDetails = false;
      this.cardFirstView = false;
      this.cardSecondView = false;
      this.ussdFirstView = false;
      this.ussdSecondView = false;
      this.transferFirstView = false;
      this.transferSecondView = false;
      this.confirmingTransactionView = true;
      this.route.queryParams.subscribe(async params => {
        this.paymentRefToSend = params.paymentReference;
        await this.modalService.getReferenceDetails(this.paymentRefToSend).toPromise().then((result: any) => {
          if (result.isSuccessful) {
            if ( result.data.finalTransactionStatus === null || result.data.paymentStatus === null ) {
              this.otherDetails = true;
              this.cardFirstView = false;
              this.cardSecondView = false;
              this.ussdFirstView = false;
              this.ussdSecondView = true;
              this.transferFirstView = false;
              this.transferSecondView = false;
              this.confirmingTransactionView = false;
              this.transferCheck = false;
              this.ussdCheck = true;
              this.cardCheck = false;
              this.toaster.open({ text: 'Transaction not confirmed yet !!', caption: 'Confirm Transaction', type: 'dark' });
            } else if ( result.data.finalTransactionStatus === 'Success' || result.data.paymentStatus === 'Received') {
              this.otherDetails = false;
              this.ussdFirstView = false;
              this.ussdSecondView = false;
              this.confirmingTransactionView = false;
              this.successView = true;
            }
          } else {
            this.toaster.open({ text: result.responseMessage || result.message, caption: 'Confirm Transaction', type: 'dark' });
          }
        }, err => {
          this.toaster.open({ text: err.error.responseMessage || err.error.message, caption: 'Confirm Transaction', type: 'dark' });
        });

      });
    } else {
      this.otherDetails = false;
      this.cardFirstView = false;
      this.cardSecondView = false;
      this.ussdFirstView = false;
      this.ussdSecondView = false;
      this.transferFirstView = false;
      this.transferSecondView = false;
      this.confirmingTransactionView = true;
      this.route.queryParams.subscribe(async params => {
        this.paymentRefToSend = params.paymentReference;
        await this.modalService.getReferenceDetails(this.paymentRefToSend).toPromise().then((result: any) => {
          if (result.isSuccessful) {
            if ( result.data.finalTransactionStatus === null || result.data.paymentStatus === null ) {
              this.otherDetails = true;
              this.cardFirstView = false;
              this.cardSecondView = false;
              this.ussdFirstView = false;
              this.ussdSecondView = false;
              this.transferFirstView = false;
              this.transferSecondView = true;
              this.confirmingTransactionView = false;
              this.transferCheck = true;
              this.ussdCheck = false;
              this.cardCheck = false;
              this.toaster.open({ text: 'Transaction not confirmed yet !!', caption: 'Confirm Transaction', type: 'dark' });
            } else if ( result.data.finalTransactionStatus === 'Success' || result.data.paymentStatus === 'Received') {
              this.otherDetails = false;
              this.transferFirstView = false;
              this.transferSecondView = false;
              this.confirmingTransactionView = false;
              this.successView = true;
            }
          } else {
            this.toaster.open({ text: result.responseMessage || result.message, caption: 'Confirm Transaction', type: 'dark' });
          }
        }, err => {
          this.toaster.open({ text: err.error.responseMessage || err.error.message, caption: 'Confirm Transaction', type: 'dark' });
        });

      });
    }
  }

  copyAccNo(val: any): void{
    this.clipBoard.copy(val);
    this.toaster.open({ text: 'Account No Copied Successfully !!', caption: 'Transfer', type: 'success' });
  }

  copyUssd(val: any): void{
    this.clipBoard.copy(val);
    this.toaster.open({ text: 'USSD Code Copied Successfully !!', caption: 'USSD', type: 'success' });
  }

  doneTransfer(): void{
    window.close();
    sessionStorage.clear();
  }

  timer(minute) {
    // let minute = 1;
    let seconds = minute * 60;
    let textSec: any = '0';
    let statSec = 60;

    const prefix = minute < 10 ? '0' : '';

    const timer = setInterval(() => {
      seconds--;
      if (statSec !== 0) { statSec--; }
      else { statSec = 59; }

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else { textSec = statSec; }

      this.displayTime = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds === 0) {
        clearInterval(timer);
      }
    }, 1000);
  }

  async resendCardOtp(): Promise<void> {
    this.spinner.show();
    const payload = {
      transactionReference: this.allCardDetails.transactionReference
    };
    await this.modalService.resendCardPaymentOtp(payload).toPromise().then((result: any) => {
      if (result.isSuccessful) {
        this.spinner.hide();
        this.toaster.open({ text: result.responseMessage || result.message, caption: 'OTP', type: 'success' });
      } else {
        this.spinner.hide();
        this.toaster.open({ text: result.responseMessage || result.message, caption: 'OTP', type: 'dark' });
      }
    }, err => {
      this.spinner.hide();
      this.toaster.open({ text: err.error.responseMessage || err.error.message, caption: 'OTP', type: 'dark' });
    });
  }

}
