import { Component , OnInit} from '@angular/core';
import { Toaster } from 'ngx-toast-notifications';
import { environment } from 'src/environments/environment.prod';
import { IdleService } from './account/auth/services/idle.service';
import { OnboardingService } from './account/auth/services/onboarding.service';
import { ModalService } from './extrapages/services/modal.service';
import { IdleModalComponent } from './extrapages/idle-modal/idle-modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {

  constructor(
    private toaster: Toaster,
    private idleService: IdleService,
    private authService: OnboardingService,
    private modalService: ModalService,
  ) {}

  ngOnInit() {
    this.initialIdleSettings();
    // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    if (sessionStorage.getItem('idle') === 'called') {
      this.openModal();
    } else {

    }
  }

  private initialIdleSettings() {
    const idleTimeoutInSeconds: number = environment.idleTimeInMinutes * 120;
    this.idleService
      .startWatching(idleTimeoutInSeconds)
      .subscribe((isTimeOut: boolean) => {
        if (isTimeOut) {
          if (sessionStorage.getItem('token')) {
            sessionStorage.setItem('idle', 'called');
            this.openModal();
          } else {

          }
        }
      });
  }

  openModal(): void {
    // tslint:disable-next-line:max-line-length
    this.modalService.openModal(IdleModalComponent, { centered: true, scrollable: true, size: 'md', windowClass: 'view-idle-modal', backdrop: 'static'});
  }
}
