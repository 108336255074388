<!-- ========== Left Sidebar Start ========== -->

<ng-template #contentTemplate>

    <!--- Sidemenu -->
    <div id="sidebar-menu" class="mt-5 pt-4">
        <!-- Left Menu Start -->
        <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
            <!-- {{menuItems}} -->

            <ng-container>
                <li>
                    <a routerLink="/dashboard" *ngIf="userRole.includes('merchant-admin') || userRole.includes('merchant-support') || userRole.includes('merchant-treasury') || userRole.includes('merchant-developer')" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bx-bar-chart-square"></i>
                        <span>Dashboard</span>
                    </a>
                </li>
                <li>
                    <a routerLink="/teams" *ngIf="userRole.includes('merchant-admin')" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bxs-group"></i>
                        <span>Teams</span>
                    </a>
                </li>
                <li>
                    <a routerLink="/wallet" *ngIf="userRole.includes('merchant-admin') || userRole.includes('merchant-support') || userRole.includes('merchant-treasury') || userRole.includes('merchant-developer')" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bx-wallet"></i>
                        <span>Wallet</span>
                    </a>
                </li>
                <li>
                    <a routerLink="/collection" *ngIf="userRole.includes('merchant-admin') || userRole.includes('merchant-support') || userRole.includes('merchant-treasury') || userRole.includes('merchant-developer')" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bx-data"></i>
                        <span>Collections</span>
                    </a>
                </li>
                <li>
                    <a routerLink="/settlements" *ngIf="userRole.includes('merchant-admin') || userRole.includes('merchant-support') || userRole.includes('merchant-treasury') || userRole.includes('merchant-developer')" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bx-receipt"></i>
                        <span>Settlements</span>
                    </a>
                </li>
                <li>
                    <a routerLink="/bills" *ngIf="userRole.includes('merchant-admin') || userRole.includes('merchant-support') || userRole.includes('merchant-treasury') || userRole.includes('merchant-developer')" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bx-file"></i>
                        <span>Bills</span>
                    </a>
                </li>
                <li>
                    <a routerLink="/payment" *ngIf="userRole.includes('merchant-admin') || userRole.includes('merchant-support') || userRole.includes('merchant-treasury') || userRole.includes('merchant-developer')" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bx-check-square"></i>
                        <span>Payment</span>
                    </a>
                </li>
                <li>
                    <a routerLink="/payout" *ngIf="userRole.includes('merchant-admin') || userRole.includes('merchant-support') || userRole.includes('merchant-treasury') || userRole.includes('merchant-developer')" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="bx bx-area"></i>
                        <span>Payout</span>
                    </a>
                </li>
            </ng-container>
        </ul>
    </div>
</ng-template>
<!-- Sidebar -->

<div class="vertical-menu">
    <ngx-simplebar class="h-100" #componentRef>
        <div *ngIf="!isCondensed">
            <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
        </div>
        <div *ngIf="isCondensed">
            <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
        </div>
    </ngx-simplebar>
</div>
<!-- Left Sidebar End -->