<ngx-spinner bdColor="rgba(0,0,0,0.5)" size="big" color="#D81616" type="ball-square-spin" [fullScreen]="true"></ngx-spinner>

<div class="hidescroll">
    <div class="modal-body" style="padding: 0px;">
        <div class="container-fluid p-0">
            <div class="row g-0">
                <div class="text-center mt-4">
                    <i class="bx bx-time-five clockIconIdle"></i>
                </div>
                <div class="col-xl-12">
                    <div class="text-center mt-2 font-size-15" style="padding: 10px 65px;">
                        <p>You have been idle for a while. Kindly input your password to continue !!</p>
                    </div>
                </div>
                <div class="col-xl-12">
                    <div class="">
                        <form autocomplete="off" [formGroup]="idleForm">
                            <div class="" style="padding: 0px 65px;">
                                <div class="mb-3">
                                    <div class="input-group auth-pass-inputgroup">
                                        <input [style.border-color]="idleForm.get('password')?.errors && (idleForm.get('password')?.touched || idleForm.get('password')?.dirty) ? '#D81616' : ''" [type]="ismyTextFieldType ? 'text' : 'password'" class="form-control formEdit w-100" formControlName="password"
                                            placeholder="Enter Your Password">
                                        <span class="input-icon" (click)="togglePasswordFieldType()"><i [class]="ismyTextFieldType ? 'far fa-eye' : 'far fa-eye-slash'"></i></span>
                                        <div class="mt-1">
                                            <span class="text-danger" *ngIf="idleForm.get('password')?.errors && (idleForm.get('password')?.touched || idleForm.get('password')?.dirty)">
                                            <span *ngIf="idleForm.get('password')?.errors?.['required']">Password is required</span>
                                            <span *ngIf="idleForm.get('password')?.errors?.['pattern']">Accept numbers, alphabet(Upper & Lower Case) and symbol only.</span>
                                            <span *ngIf="idleForm.get('password')?.errors?.['minlength']">Minimum of 8 characters only.</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-4 pb-2 pt-3" style="padding: 0px 65px;">
                                <div class="row">
                                    <div class="col-6 col-xl-6">
                                        <button class="btn continueBtn w-100" [disabled]="!idleForm.valid" (click)="unlockScreen()">Continue</button>
                                    </div>
                                    <div class="col-6 col-xl-6">
                                        <button class="btn btn-block logoutBtn w-100" (click)="logout()">Logout</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- end row -->
        </div>
    </div>
</div>