export const environment = {
  production: true,
  defaultauth: 'fackbackend',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  },
  baseUrl: 'https://sandbox-apigateway.myspen.com/session/api/v1',
  baseUrlMerchant: 'https://sandbox-apigateway.myspen.com/merchant/api/v1',
  baseUrlCheckout: 'https://sandbox-apigateway.myspen.com/spen-checkout/api/v1',
  idleTimeInMinutes: 10,

  // Production
  // baseUrl: 'https://apigateway.myspen.com/session/api/v1',
  // baseUrlMerchant: 'https://apigateway.myspen.com/merchant/api/v1',
  // baseUrlCheckout: 'https://apigateway.myspen.com/spen-checkout/api/v1',

};
