import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { OnboardingService } from 'src/app/account/auth/services/onboarding.service';
import { ModalService } from '../services/modal.service';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-visa-confirmation',
  templateUrl: './visa-confirmation.component.html',
  styleUrls: ['./visa-confirmation.component.scss']
})
export class VisaConfirmationComponent implements OnInit {
  paymentRefToSend: any;
  referenceDetails: any;

  confirmingTransactionView = true;
  successView = false;
  errorView = false;

  constructor(
    private spinner: NgxSpinnerService,
    private toaster: Toaster,
    private onboardingService: OnboardingService,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private clipBoard: ClipboardService,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.getUrlInformation();
  }

  async getUrlInformation(): Promise<void>{
    this.spinner.show();
    this.route.queryParams.subscribe(async params => {
      this.paymentRefToSend = params.transactionReference;

      await this.modalService.getReferenceDetails(this.paymentRefToSend).toPromise().then((result: any) => {
        if (result.isSuccessful) {
          this.spinner.hide();
          this.referenceDetails = result.data;

          if ( result.data.finalTransactionStatus === null || result.data.paymentStatus === null ) {
            this.confirmingTransactionView = false;
            this.successView = false;
            this.errorView = true;
          } else if ( result.data.finalTransactionStatus === 'Success' || result.data.paymentStatus === 'Received') {
            this.confirmingTransactionView = false;
            this.successView = true;
            this.errorView = false;
          }

        } else {
          this.spinner.hide();
          this.toaster.open({ text: result.responseMessage || result.message, caption: 'Merchant Reference', type: 'dark' });
        }
      }, err => {
        this.spinner.hide();
        this.toaster.open({ text: err.error.responseMessage || err.error.message, caption: 'Merchant Reference', type: 'dark' });
      });

    });
  }

  doneTransfer(): void{
    window.close();
  }

  async tryAgain(): Promise<void>{
    this.spinner.show();
    await this.modalService.getReferenceDetails(this.paymentRefToSend).toPromise().then((result: any) => {
      if (result.isSuccessful) {
        this.spinner.hide();
        this.referenceDetails = result.data;

        if ( result.data.finalTransactionStatus === null || result.data.paymentStatus === null ) {
          this.confirmingTransactionView = false;
          this.successView = false;
          this.errorView = true;
        } else if ( result.data.finalTransactionStatus === 'Success' || result.data.paymentStatus === 'Received') {
          this.confirmingTransactionView = false;
          this.successView = true;
          this.errorView = false;
        }

      } else {
        this.spinner.hide();
        this.toaster.open({ text: result.responseMessage || result.message, caption: 'Merchant Reference', type: 'dark' });
      }
    }, err => {
      this.spinner.hide();
      this.toaster.open({ text: err.error.responseMessage || err.error.message, caption: 'Merchant Reference', type: 'dark' });
    });
  }

}
