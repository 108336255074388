import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgOtpInputModule } from 'ng-otp-input';

import { ExtrapagesRoutingModule } from './extrapages-routing.module';

import { Page404Component } from './page404/page404.component';
import { KycmodalComponent } from './kycmodal/kycmodal.component';
import { IdleModalComponent } from './idle-modal/idle-modal.component';
import { CheckoutmodalComponent } from './checkoutmodal/checkoutmodal.component';
import { VisaPageComponent } from './visa-page/visa-page.component';
import { VisaConfirmationComponent } from './visa-confirmation/visa-confirmation.component';

@NgModule({
  // tslint:disable-next-line:max-line-length
  declarations: [ Page404Component, KycmodalComponent, IdleModalComponent, CheckoutmodalComponent, VisaPageComponent, VisaConfirmationComponent ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    CarouselModule,
    ExtrapagesRoutingModule,
    NgOtpInputModule,
    NgxSpinnerModule
  ]
})
export class ExtrapagesModule { }
